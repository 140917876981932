import { useNavigate, useRoutes } from 'react-router-dom';
import router from 'src/router';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { CssBaseline } from '@mui/material';
import ThemeProvider from './theme/ThemeProvider';
import { useEffect } from 'react';
import Api from './api';
import { Slide, ToastContainer } from 'react-toastify';
import DialogService from 'src/content/dialog/BookingDetails';
import 'react-toastify/dist/ReactToastify.css';
import CleanerReviews from './content/dialogv2/CleanerReviewsDetails';

function App() {
  const navigate = useNavigate();
  const content = useRoutes(router);

  useEffect(() => {
    if (Api.getStoredToken()) {
      navigate('/dashboard', { replace: true });
    } else {
      navigate('/', { replace: true });
    }
  }, []);

  const toastStyles = {
    autoClose: 3000,
    transition: Slide,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
  }

  return (
    <ThemeProvider>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <CssBaseline />
        {content}
        <ToastContainer {...toastStyles} />
        <CleanerReviews />
        <DialogService />
      </LocalizationProvider>
    </ThemeProvider>
  );
}
export default App;
