import PropTypes from 'prop-types';

/**
 * This file contains all the interfaces and constants used in the project
 *
 * @export
 * @interface file
 */

/**
 * @description CleanerStatus is used to define the status of the cleaner
 * @type {number}
 */
export type CleanerStatus = 1 | 2;

/**
 * @description BookingStatusModel is used to define the status of the booking
 * @type {number}
 */
export type BookingStatusModel = 1 | 2 | 3 | 4 | 5 | 6;

/**
 * @description BookingFilter is used to define the filter of the booking
 * @type {number}
 */
export type BookingFilter = 0 | BookingStatusModel;

/**
 * @description RefundStatus is used to define the status of the refund
 * @type {number}
 */
export type RefundStatus = 1 | 2 | null;

/**
 * @description AdditionalTimeInterface is used to define the status of the additional time
 * @type {number}
 */
export type AdditionalTimeInterface = 0 | 1 | 2 | null;

/**
 * @description AdditionalTimeConstType is used to define the constant of the additional time
 * @type {number}
 */
type AdditionalTimeConstType = {
  [key in AdditionalTimeInterface]: string;
};

/**
 * @description AdditionalTimeConst is used to define the constant of the additional time
 * @type {number}
 */
export const AdditionalTimeConst: AdditionalTimeConstType = {
  0: 'Pending Request',
  1: 'Request Accepted',
  2: 'Request Rejected',
  [null as any]: 'Request Cancelled'
};

/**
 * @description RefundStatusConst is used to define the constant of the refund
 * @type {number}
 */
export const RefundStatusConst = {
  1: 'Pending',
  2: 'Completed',
  null: 'N/A'
};

/**
 * @description ColorPalette is used to define the color of the palette
 * @type {number}
 */
export type ColorPalette =
  | 'light'
  | 'dark'
  | 'primary'
  | 'success'
  | 'warning'
  | 'secondary'
  | 'error'
  | 'info';

/**
 * @description StatusInterface is used to define the interface of the status
 * @type {number}
 */
type StatusInterface = {
  text: string;
  color: ColorPalette;
};

/**
 * @description BookingStatusMapInterface is used to define the interface of the booking status map
 * @type {number}
 */
type BookingStatusMapInterface = {
  0: StatusInterface;
  1: StatusInterface;
  2: StatusInterface;
  3: StatusInterface;
  4: StatusInterface;
  5: StatusInterface;
  6: StatusInterface;
};

/**
 * @description bookingStatusMap is used to define the booking status map
 * @type {number}
 */
export const bookingStatusMap: BookingStatusMapInterface = {
  0: {
    text: 'All',
    color: 'secondary'
  },
  1: {
    text: 'Open',
    color: 'success'
  },
  2: {
    text: 'Accepted',
    color: 'primary'
  },
  3: {
    text: 'On My Way',
    color: 'info'
  },
  4: {
    text: 'In Progress',
    color: 'warning'
  },
  5: {
    text: 'Completed',
    color: 'primary'
  },
  6: {
    text: 'Cancelled',
    color: 'error'
  }
};

export type ServiceStatus = 1 | 0;

/**
 * @description This interface is used to define the status of a booking
 */
export const BOOKING_STATUS = {
  OPEN: 1,
  ACCEPTED: 2,
  ON_MY_WAY: 3,
  IN_PROGRESS: 4,
  COMPLETED: 5,
  CANCELLED: 6
};

/**
 * @description BookingCancel interface
 */
export interface BookingCancel {
  bookingCancelId: string;
  charge: number;
  refundAmount: number;
  refundStatus: RefundStatus;
  refundBy: null;
  reason: string;
  createdBy: number;
  createdAt: number;
  cancelBy: {
    fname: string;
    lname: string;
  };
  refundsBy: null;
}

/**
 * @description AdditionalTimeDetails interface
 */
export interface AdditionalTimeDetails {
  assignTo: string;
  bookingId: string;
  charge: number;
  cleaningAdditionId: string;
  createdAt: number;
  createdBy: string;
  deletedAt: number;
  hours: number;
  reason: string;
  serviceRate: number;
  status: AdditionalTimeInterface;
  updatedAt: number;
}

/**
 * @description Customer interface
 */
export interface Customer {
  profilePic: string;
  userId: number;
  fname: string;
  lname: string;
  contactNumber: string;
  dialCode: string;
  email: string;
}

/**
 * @description Bookings interface
 */
export interface Bookings {
  bookingId: string;
  serviceId: string;
  cleanerId: string;
  startTime: string;
  endTime: string;
  houseSize: number;
  rooms: number;
  bathRooms: number;
  cleanerCount: number;
  workingHours: number;
  amount: number;
  instructions: string;
  address: {
    addId: number;
    add1: string;
    add2: string;
    landmark: string;
    city: string;
    state: string;
    country: string;
    zip: string;
    title: string;
    lat: number;
    long: number;
  };
  serviceRate: number;
  offerStatus: number;
  status: BookingStatusModel;
  service: {
    image: string;
    title: string;
    description: string;
    hrlyRate: number;
    promotionStatus: number;
    promotionalPrice: number;
  };
  bookedBy: {
    profilePic: string;
    userId: number;
    fname: string;
    lname: string;
    contactNumber: string;
    dialCode: string;
    email: string;
  };
  cleaner: {
    profilePic: string;
    userId: number;
    fname: string;
    lname: string;
    contactNumber: string;
    dialCode: string;
    email: string;
    AvgRate: number;
    totalRate: number;
  };
  bookingCancel: BookingCancel[];
  additionalTimeDetails: AdditionalTimeDetails[];
  bookingReview: {
    rate: number;
    tag: string;
    description: string;
  };
}

/**
 * @description CustomersModel interface
 */
export interface CustomersModel {
  profilePic: string;
  userId: number;
  fname: string;
  lname: string;
  dialCode: string;
  email: string;
  contactNumber: string;
  status: CleanerStatus;
  address: string;
  drivingLicense: string;
  socialSecurity: string;
  isAgreementUploaded: boolean;
  agreementDocument: string;
  createdAt: string;
  updatedAt: string;
}

/**
 * @description CleanersModel interface
 */
export interface CleanersModel {
  profilePic: string;
  userId: number;
  fname: string;
  lname: string;
  dialCode: string;
  email: string;
  contactNumber: string;
  status: CleanerStatus;
  address: string;
  drivingLicense: string;
  totalAccrual: number;
  socialSecurity: string;
  isAgreementUploaded: boolean;
  agreementDocument: string;
  createdAt: string;
  updatedAt: string;
  AvgRate: number;
  totalRate: number;
}

export interface CleanerReviewModel {
  cleanerId: number;
  bookingReviewId: string;
  bookingId: string;
  rate: number;
  tag: string;
  description: string;
  createdAt: string;
  cleanerDetail: {
    fname: string;
    lname: string;
  };
  reviewBy: {
    fname: string;
    lname: string;
  };
  bookingDetail: {
    startTime: string;
    createdAt: string;
    workingHours: number;
    service: {
      title: string;
    };
    additionalTimeDetails: [
      {
        hours: number;
      }
    ];
  };
}

/**
 * @description FormArray interface
 */
export interface FormArray {
  id: string;
  name: string;
  readOnly: boolean;
  defaultValue: string;
  required: boolean;
  type: string;
  placeholder: string;
}

/**
 * @description UserDetailsModel interface
 */
export const UserDetailsModel = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  action: PropTypes.string.isRequired,
  user: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  data: PropTypes.object
};

export interface ServiceModel {
  description: string;
  hrlyRate: number;
  image: string;
  promotionStatus: ServiceStatus;
  promotionalPrice: number;
  serviceId: string;
  title: string;
  updatedAt: string;
}
