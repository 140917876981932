import { useState } from 'react';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import Api from 'src/api';
import { toast } from 'react-toastify';
import SideDrawer from 'src/components/RightDrawer';
import CleanerReviewList from './CleanerReviews';
import { CleanerReviewModel } from 'src/models/Model';
import { Utils } from 'src/api/Utils';

/**
 * 
 * @returns JSX.Element
 * @description This component is used to show cleaner reviews in a side drawer when clicked on cleaner name in review listing page.
 */
const CleanerReviews = () => {
  // useSearchParams hook is used to get the search params from the url.
  const [searchParams, setSearchParams] = useSearchParams();
  // open state is used to open the side drawer.
  const [open, setOpen] = useState(false);
  // reviewDetails state is used to store the cleaner reviews.
  const [reviewDetails, setReviewDetails] = useState<CleanerReviewModel[]>(null);
  // loading state is used to show the loader.
  const [loading, setLoading] = useState(false);

  // useEffect hook is used to get the cleaner reviews when cleanerId is present in the search params.
  useEffect(() => {
    // cleanerId is used to get the cleaner reviews.
    const id: number = Number(searchParams.get('cleanerId'));

    // if cleanerId is not present in the search params then close the side drawer and set the reviewDetails to null.
    if (!id) {
      handleClose();
    } else {

      // if cleanerId is present in the search params then open the side drawer and get the cleaner reviews.
      setOpen(true);
      setLoading(true);

      // Api.ratingList is used to get the cleaner reviews. It takes two parameters. First parameter is the query string and second parameter is the callback function.
      Api.ratingList(`cleanerId=${id}&sortBy=rate&recordsPerPage=20`, (response: any) => {
        if (response.data.status === 200) {
          setReviewDetails(response.data.payload.data);
        } else {
          handleClose();
          toast.error('Oops! Something went wrong.');
        }
        setLoading(false)
      });
    }
  }, [searchParams]);

  const handleClose = () => {
    if (searchParams.has('cleanerId')) {
      searchParams.delete('cleanerId'); /* removes cleaner Id */
      searchParams.delete('cleaner'); /* removes cleaner name */
      setSearchParams(searchParams);
    }
    setOpen(false);
    // setTimeout is used to set the reviewDetails to null after 500ms.
    setTimeout(() => setReviewDetails(null), 500);
  };

  // cleanerName is used to get the cleaner name from the search params.
  // Utils.stringFallback is used to get the fallback value if cleanerName is null or undefined.
  const cleanerName = Utils.stringFallback(searchParams.get('cleaner'));

  return (
    <>
      <SideDrawer header={`Cleaner Reviews - ${cleanerName}`} loading={loading} open={open} setOpen={handleClose}>
        <CleanerReviewList loading={loading} data={reviewDetails} />
      </SideDrawer>
    </>
  );
};

export default CleanerReviews;
