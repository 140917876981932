import { BookingStatusModel, RefundStatus } from './../models/Model';
import {
  AGREEMENT_API,
  BOOKING_DETAILS,
  CLEANER,
  CLEANER_STATUS,
  GET_BOOKINGS,
  GET_USER_DETAILS,
  GET_USER_LIST,
  LOGIN,
  UPLOAD_PROFILE_PICTURE,
  DASHBOARD,
  UPDATE_CANCEL_BOOKING_STATUS,
  GET_SERVICE_OFFER_LIST,
  GET_SERVICE_OFFER_DETAIL,
  CLEANER_RATING
} from './ApiUrl';

// set base url from env
const REACT_APP_BASE_URL = String(process.env.REACT_APP_BASE_URL);

// set api url
const REACT_APP_LOGIN = REACT_APP_BASE_URL + LOGIN;
const REACT_APP_CLEANER = REACT_APP_BASE_URL + CLEANER;
const REACT_APP_GET_USER_LIST = REACT_APP_BASE_URL + GET_USER_LIST;
const REACT_APP_GET_USER_DETAILS = REACT_APP_BASE_URL + GET_USER_DETAILS;
const REACT_APP_UPLOAD_PROFILE_PICTURE =
  REACT_APP_BASE_URL + UPLOAD_PROFILE_PICTURE;
const REACT_APP_AGREEMENT_API = REACT_APP_BASE_URL + AGREEMENT_API;
const REACT_APP_GET_BOOKINGS = REACT_APP_BASE_URL + GET_BOOKINGS;
const REACT_APP_BOOKING_DETAILS = REACT_APP_BASE_URL + BOOKING_DETAILS;
const REACT_APP_CLEANER_STATUS = REACT_APP_BASE_URL + CLEANER_STATUS;
const REACT_APP_DASHBOARD = REACT_APP_BASE_URL + DASHBOARD;
const REACT_APP_UPDATE_CANCEL_BOOKING_STATUS =
  REACT_APP_BASE_URL + UPDATE_CANCEL_BOOKING_STATUS;
const REACT_APP_GET_SERVICE_OFFER_LIST =
  REACT_APP_BASE_URL + GET_SERVICE_OFFER_LIST;
const REACT_APP_GET_SERVICE_OFFER_DETAIL =
  REACT_APP_BASE_URL + GET_SERVICE_OFFER_DETAIL;
const REACT_APP_CLEANER_RATING = REACT_APP_BASE_URL + CLEANER_RATING;

/**
 * @description Api class for all api calls and response handling for all api calls in the application.
 * @method login - login api call
 * @method changeCancelBookingRefundStatus - change cancel booking refund status api call
 * @method getBookingDetails - get booking details api call
 * @method getSearchResults - get search results api call
 * @method changeCleanerStatus - change cleaner status api call
 * @method getCleanerAccrual - get cleaner accrual api call
 * @method getList - get list api call
 * @method getBookings - get bookings api call
 * @method getBookingDetails - get booking details api call
 * @method getUserDetails - get user details api call
 * @method addCleaner - add cleaner api call
 * @method updateCleaner - update cleaner api call
 * @method handleAgreementUpload - handle agreement upload api call
 * @method handleGetUserImg - handle get user image api call
 * @method logout - logout api call
 * @method getStoredToken - get stored token api call
 * @method removeStoredToken - remove stored token api call
 * @method serviceOfferList - service offer list api call
 * @method getServiceDetails - get service details api call
 * @method updateServiceDetail - update service details api call
 * @method ratingList - rating list api call
 *
 */
class Api {
  /**
   *
   * @param email
   * @param password
   * @param rememberMe
   * @param callback
   * @description login api call with email, password, remember me option and callback function as params.
   */
  static login = (
    email: String,
    password: String,
    rememberMe: boolean,
    callback: Function
  ) => {
    // set headers for api call
    var myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/x-www-form-urlencoded');

    // fetch api call with headers and body params and response handling with callback
    fetch(REACT_APP_LOGIN, {
      // POST method
      method: 'POST',
      headers: myHeaders,
      redirect: 'follow',
      body: new URLSearchParams({
        email: String(email),
        password: String(password)
      })
    })
      // response handling with json format
      .then((response) => response.json())
      // response handling with callback function with status and data params
      .then((result) => {
        // if status is 200 then set token in local storage or session storage based on remember me option
        if (result.status === 200) {
          // if remember me is true then set token in local storage else set token in session storage
          if (rememberMe) {
            // remove token from session storage and set token in local storage.
            window.sessionStorage.removeItem('token');
            // set token in local storage with key token and value is token from response data.
            window.localStorage.setItem('token', result.payload?.data?.token);
          } else {
            // remove token from local storage and set token in session storage.
            window.localStorage.removeItem('token');
            // set token in session storage with key token and value is token from response data.
            window.sessionStorage.setItem('token', result.payload?.data?.token);
          }
        }
        // callback function with status and data params with status and data from response data.
        callback({ status: true, data: result });
      })
      .catch((err) => {
        // callback function with status and data params with status and data from response data.
        callback({ status: false, data: err });
      });
  };

  /**
   *
   * @param bookingId
   * @param status
   * @param callback
   * @description change cancel booking refund status api call with booking id, status and callback function as params.
   */
  static changeCancelBookingRefundStatus = (
    bookingId: number,
    status: RefundStatus,
    callback: Function
  ) => {
    // get token from local storage or session storage
    const token = this.getStoredToken();

    // set headers for api call
    var myHeaders = new Headers();
    // set authorization header with token from local storage or session storage with Bearer prefix and space after Bearer prefix and token value.
    myHeaders.append('Authorization', 'Bearer ' + token);
    myHeaders.append('Content-Type', 'application/json');

    // fetch api call with headers and body params and response handling with callback.
    var raw = JSON.stringify({
      refundStatus: String(status)
    });

    // fetch api call with headers and body params and response handling with callback.
    fetch(
      `${REACT_APP_UPDATE_CANCEL_BOOKING_STATUS}?bookingCancelId=${bookingId}`,
      {
        method: 'PUT',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      }
    )
      .then((response) => response.json())
      .then((result) => callback({ status: true, data: result }))
      .catch((error) => callback({ status: false, data: error }));
  };

  /**
   *
   * @param callback
   * @description get booking details api call with callback function as params.
   */
  static getBookingStats = (callback: Function) => {
    // get token from local storage or session storage
    const token = this.getStoredToken();

    // set headers for api call
    var myHeaders = new Headers();
    myHeaders.append('Authorization', 'Bearer ' + token);

    // fetch api call with headers and body params and response handling with callback.
    fetch(`${REACT_APP_DASHBOARD}`, {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    })
      .then((response) => response.json())
      .then((result) => callback({ status: true, data: result }))
      .catch((error) => callback({ status: false, data: error }));
  };

  /**
   *
   * @param roleId
   * @param searchTerm
   * @param callback
   * @description get search results api call with role id, search term and callback function as params.
   */
  static getSearchResults = (
    roleId: number,
    searchTerm: string,
    callback: Function
  ) => {
    // AbortController is used to cancel the request to prevent request queueing in case of multiple requests.
    const controller = new AbortController();
    // signal is used to cancel the request to prevent request queueing in case of multiple requests.
    const signal = controller.signal;

    // get token from local storage or session storage
    const token = this.getStoredToken();

    var myHeaders = new Headers();
    myHeaders.append('Authorization', 'Bearer ' + token);

    // fetch api call with headers and body params and response handling with callback.
    fetch(
      `${REACT_APP_GET_USER_LIST}?search={"search":"${searchTerm}","roleId":"${roleId}"}&recordsPerPage=120`,
      {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow',
        signal: signal
      }
    )
      .then((response) => response.json())
      .then((result) => callback({ status: true, data: result }))
      .catch((error) => callback({ status: false, data: error }));

    // require logic to cancel the request to prevent request queueing
    // controller.abort();
  };

  /**
   *
   * @param userId
   * @param userVerified
   * @param callback
   * @description change cleaner status api call with user id, user verified status and callback function as params.
   */
  static changeCleanerStatus = (userId, userVerified, callback) => {
    const token = this.getStoredToken();

    // set headers for api call with token from local storage or session storage with Bearer prefix and space after Bearer prefix and token value.
    var myHeaders = new Headers();
    myHeaders.append('Authorization', 'Bearer ' + token);

    var formData = new FormData();
    formData.append('status', userVerified);

    fetch(`${REACT_APP_CLEANER_STATUS}?cleanerId=${userId}`, {
      method: 'PUT',
      headers: myHeaders,
      body: formData,
      redirect: 'follow'
    })
      .then((response) => response.json())
      .then((result) => callback({ status: true, data: result }))
      .catch((error) => callback({ status: false, data: error }));
  };

  /**
   *
   * @param cleaner
   * @param record
   * @param page
   * @param callback
   * @description get cleaner accrual api call with cleaner, record, page and callback function as params.
   */
  static getCleanerAccrual = (
    cleaner: any,
    record: number | null,
    page: number | null,
    callback: Function
  ) => {
    this.getBookings(cleaner.userId, record, page, (response) => {
      callback({
        status: true,
        data: {
          fname: cleaner.fname,
          lname: cleaner.lname,
          totalAccrual: cleaner.totalAccrual,
          bookings: response.data.payload.data,
          status: response.data.status
        }
      });
    });
  };

  /**
   * @param roleId
   * @param record
   * @param page
   * @param callback
   * @description get user list api call with role id, record, page and callback function as params.
   */
  static getList = (
    roleId: number | null,
    record: number | null,
    page: number | null,
    callback: Function
  ) => {
    const token = this.getStoredToken();

    var myHeaders = new Headers();
    myHeaders.append('Authorization', 'Bearer ' + token);

    // todo - records per page need to be updated
    fetch(
      `${REACT_APP_GET_USER_LIST}?search={"roleId":${roleId}}&recordsPerPage=${record}&pageNumber=${page}`,
      {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
      }
    )
      .then((response) => response.json())
      .then((result) => callback({ status: true, data: result }))
      .catch((error) => callback({ status: false, data: error }));
  };

  /**
   *
   * @param cleanerId
   * @param record
   * @param page
   * @param callback
   * @description get bookings api call with cleaner id, record, page and callback function as params.
   */
  static getBookings = (
    cleanerId: any,
    record: number | null,
    page: number | null,
    callback: Function
  ) => {
    const token = this.getStoredToken();

    var myHeaders = new Headers();
    myHeaders.append('Authorization', 'Bearer ' + token);

    fetch(
      `${REACT_APP_GET_BOOKINGS}?&recordsPerPage=${record}&pageNumber=${page}${
        cleanerId ? `&search={"cleanerId": ${cleanerId}}` : ''
      }`,
      {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
      }
    )
      .then((response) => response.json())
      .then((result) => callback({ status: true, data: result }))
      .catch((error) => callback({ status: false, data: error }));
  };

  /**
   *
   * @param bookingId
   * @param callback
   * @description get booking details api call with booking id and callback function as params.
   */
  static getBookingDetails(bookingId: number, callback: Function) {
    const token = this.getStoredToken();

    var myHeaders = new Headers();
    myHeaders.append('Authorization', 'Bearer ' + token);

    fetch(`${REACT_APP_BOOKING_DETAILS}?bookingId=${bookingId}`, {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    })
      .then((response) => response.json())
      .then((result) => callback({ status: true, data: result }))
      .catch((error) => callback({ status: false, data: error }));
  }

  /**
   *
   * @param userId
   * @param callback
   * @description get user details api call with user id and callback function as params.
   */
  static getUserDetails = (userId: number, callback: Function) => {
    const token = this.getStoredToken();

    var myHeaders = new Headers();
    myHeaders.append('Authorization', 'Bearer ' + token);

    fetch(`${REACT_APP_GET_USER_DETAILS}?userId=${userId}`, {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    })
      .then((response) => response.json())
      .then((result) => callback({ status: true, data: result }))
      .catch((error) => callback({ status: false, data: error }));
  };

  /**
   *
   * @param formData
   * @param callback
   * @description add cleaner api call with form data and callback function as params.
   */
  static addCleaner = (formData: any, callback: Function) => {
    const token = this.getStoredToken();

    var myHeaders = new Headers();
    myHeaders.append('Authorization', 'Bearer ' + token);

    formData.append('dialCode', '+1');
    formData.append('earningPercentage', '70');

    fetch(REACT_APP_CLEANER, {
      method: 'POST',
      headers: myHeaders,
      body: formData,
      redirect: 'follow'
    })
      .then((response) => response.json())
      .then((result) => {
        console.log(result);
        callback({ status: true, data: result });
      })
      .catch((error) => {
        console.log(error);
        callback({ status: false, data: error });
      });
  };

  /**
   *
   * @param cleanerId
   * @param formData
   * @param callback
   * @description update cleaner api call with cleaner id, form data and callback function as params.
   */
  static updateCleaner = (
    cleanerId: string,
    formData: any,
    callback: Function
  ) => {
    const token = this.getStoredToken();

    var myHeaders = new Headers();
    myHeaders.append('Authorization', 'Bearer ' + token);

    // formData.append('agreementDocument', 'user/92641662698837363.pdf');

    // todo this ----------------------------------------------------------
    fetch(`${REACT_APP_CLEANER}?userId=${cleanerId}`, {
      method: 'PUT',
      headers: myHeaders,
      body: formData,
      redirect: 'follow'
    })
      .then((response) => response.json())
      .then((result) => {
        console.log(result);
        callback({ status: true, data: result });
      })
      .catch((error) => {
        console.log(error);
        callback({ status: false, data: error });
      });
  };

  /**
   *
   * @param file
   * @param callback
   * @description upload agreement api call with file and callback function as params.
   */
  static handleAgreementUpload = (file: any, callback: Function) => {
    var formdata = new FormData();
    formdata.append('file', file);
    formdata.append('type', '3'); // 3 = agreement

    fetch(REACT_APP_AGREEMENT_API, {
      method: 'POST',
      body: formdata,
      redirect: 'follow'
    })
      .then((response) => response.json())
      .then((result) => callback({ status: true, data: result }))
      .catch((error) => callback({ status: false, data: error }));
  };

  /**
   *
   * @param file
   * @param callback
   * @description upload profile photo api call with file and callback function as params.
   */
  static handleGetUserImg = async (file: any, callback: Function) => {
    var formdata = new FormData();
    formdata.append('file', file);
    formdata.append('type', '1'); // 1 = profile photo

    fetch(`${REACT_APP_UPLOAD_PROFILE_PICTURE}`, {
      method: 'POST',
      body: formdata,
      redirect: 'follow'
    })
      .then((response) => response.json())
      .then((result) => callback({ status: true, data: result }))
      .catch((error) => callback({ status: false, data: error }));
  };

  /**
   * @description get cleaner list api call.
   */
  static logOut = () => {
    window.localStorage.removeItem('token');
    window.location.reload();
  };

  /**
   *
   * @returns token
   * @description get stored token from local storage and session storage.
   */
  static getStoredToken = () => {
    const localToken = window.localStorage.getItem('token');
    const sessionToken = window.sessionStorage.getItem('token');

    const token = localToken !== null ? localToken : sessionToken;

    return token !== undefined ? token : false;
  };

  /**
   * @description remove stored token from local storage and session storage.
   */
  static removeStoredToken = () => {
    window.localStorage.removeItem('token');
    window.sessionStorage.removeItem('token');
  };

  /**
   *
   * @param callback
   * @description get service offer list api call with callback function as params.
   */
  static serviceOfferList = (callback: Function) => {
    const token = this.getStoredToken();
    var myHeaders = new Headers();
    myHeaders.append('Authorization', 'Bearer ' + token);

    fetch(`${REACT_APP_GET_SERVICE_OFFER_LIST}?sortOrder=ASC`, {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    })
      .then((response) => response.json())
      .then((result) => callback({ status: true, data: result }))
      .catch((error) => callback({ status: false, data: error }));
  };

  /**
   *
   * @param serviceId
   * @param callback
   * @description get service detail api call with service id and callback function as params.
   */
  static getServiceDetails = (serviceId: number, callback: Function) => {
    const token = this.getStoredToken();

    var myHeaders = new Headers();
    myHeaders.append('Authorization', 'Bearer ' + token);

    fetch(`${REACT_APP_GET_SERVICE_OFFER_DETAIL}?serviceId=${serviceId}`, {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    })
      .then((response) => response.json())
      .then((result) => callback({ status: true, data: result }))
      .catch((error) => callback({ status: false, data: error }));
  };

  /**
   *
   * @param serviceId
   * @param formData
   * @param callback
   * @description update service detail api call with service id, form data and callback function as params.
   */
  static updateServiceDetail = (
    serviceId: number,
    formData: FormData,
    callback: Function
  ) => {
    const token = this.getStoredToken();

    var myHeaders = new Headers();
    myHeaders.append('Authorization', 'Bearer ' + token);
    myHeaders.append('Content-Type', 'application/json');
    var raw = JSON.stringify(formData);

    fetch(`${REACT_APP_GET_SERVICE_OFFER_LIST}?serviceId=${serviceId}`, {
      method: 'PUT',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    })
      .then((response) => response.json())
      .then((result) => callback({ status: true, data: result }))
      .catch((error) => callback({ status: false, data: error }));
  };

  /**
   *
   * @param filter
   * @param callback
   * @description get cleaner rating list api call with filter and callback function as params.
   */
  static ratingList = (filter: string, callback: Function) => {
    const token = this.getStoredToken();
    var myHeaders = new Headers();
    myHeaders.append('Authorization', 'Bearer ' + token);

    fetch(`${REACT_APP_CLEANER_RATING}?${filter}`, {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    })
      .then((response) => response.json())
      .then((result) => callback({ status: true, data: result }))
      .catch((error) => callback({ status: false, data: error }));
  };
}

export default Api;
