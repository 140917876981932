import { useState } from 'react';
import { useEffect, useContext } from 'react';
import { useSearchParams } from 'react-router-dom';
import Api from 'src/api';
import { toast } from 'react-toastify';
import SideDrawer from 'src/components/RightDrawer';
import BookingDetails from 'src/content/dialogv2/BookingDetails';
import { GlobalContext } from 'src/contexts/GlobalContext';

const DialogService = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [open, setOpen] = useState(false);
  const [bookingDetails, setBookingDetails] = useState(null);
  const [loading, setLoading] = useState(false);
  const { refreshBookingDetails } = useContext(GlobalContext)

  useEffect(() => {
    const id: number = Number(searchParams.get('id')) | Number(searchParams.get('navigate_Id'));
    if (!id) {
      handleClose();

      setTimeout(() => setBookingDetails(null), 500);
    } else {
      setOpen(true);
      setLoading(true);
      Api.getBookingDetails(id, (response: any) => {
        if (response.data.status === 200) {
          setBookingDetails(response.data.payload.data);
        } else {
          handleClose();
          toast.error('Oops! Something went wrong.');
        }
        setTimeout(() => setLoading(false), 0);
      });
    }
  }, [searchParams, refreshBookingDetails]);

  const handleClose = () => {
    // if search param is changed from review listing
    if (searchParams.has('navigate_Id')) {
      window.history.back();
    }

    // if search param is changed from booking details
    if (searchParams.has('id')) {
      searchParams.delete('id');
      setSearchParams(searchParams);
    }
    setOpen(false);
  };

  return (
    <>
      <SideDrawer header="Booking Details" loading={loading} open={open} setOpen={handleClose}>
        <BookingDetails loading={loading} data={bookingDetails} />
      </SideDrawer>
    </>
  );
};

export default DialogService;
