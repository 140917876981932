import { Card, CardContent, Grid, Typography } from '@mui/material';
import moment from 'moment';
import React from 'react'
import { URLSearchParamsInit } from 'react-router-dom';
import { CleanerReviewModel } from 'src/models/Model';
import Label from '../Label';
import StarRating from '../StarRatings';


// This is an interface that defines the props for the component
interface ReviewCardProps {
    // The data to be displayed in the card
    data: CleanerReviewModel;
    // A function that navigates to another page in the app
    navigate?: (nextInit: URLSearchParamsInit, navigateOptions?: {
        replace?: boolean;
        state?: any;
    }) => void;
    // A boolean that determines whether to display the date of the review
    displayDate?: boolean;
}

// This is a functional component that renders a review card
const ReviewCard = (props: ReviewCardProps) => {
    // data is the data to be displayed in the card
    const { data } = props

    // styles is an object that contains the styles for the component
    const styles = {
        // flexBetween is a style that displays the children of the element in a flexbox with space between them
        flexBetween: {
            display: 'flex',
            justifyContent: 'space-between'
        },
        // grayText is a style that displays the text in a gray color
        grayText: {
            fontSize: '14px',
            color: '#999999'
        },
        // buttonSmall is a style that displays a button with a small padding
        buttonSmall: {
            padding: '2px 3px',
            color: 'primary'
        },
        // grayCard is a style that displays a card with a gray background color
        grayCard: {
            backgroundColor: '#f8f8f8',
            borderRadius: '10px',
            marginTop: '10px',
            padding: '13px'
        },
        // profilePicture is a style that displays a profile picture with a width and height of 60px
        profilePicture: {
            width: '60px',
            height: '60px'
        },
        // cardView is a style that displays a card with a border radius of 15px and a box shadow
        cardView: {
            borderRadius: '15px',
            boxShadow: '0px 5px 15px #e0e0e0',
        },
        // cardTitle is a style that displays a title with a font size of 16px and a bold font weight
        cardTitle: {
            fontSize: '16px',
            fontWeight: 'bolder'
        }
    };

    /**
     *
     * @param {any} props - The props of the component.
     * @returns {JSX.Element} - The JSX to render.
     * @description DescriptionText is a component that displays text with a font size of 14px and a gray color
     */
    const DescriptionText = (props: any) => {
        const { children } = props;

        // Return JSX
        return (
            <Typography sx={{ ...props.sx, ...styles.grayText }}>
                {children}
            </Typography>
        );
    };

    /**
     * 
     * @param children 
     * @returns JSX.Element
     * @description CardTitle is a component that displays a title with a font size of 16px and a bold font weight
     */
    const CardTitle = ({ sx = {}, children }): JSX.Element => {
        return (
            <Typography sx={{ ...styles.cardTitle, ...sx }}>
                {children}
            </Typography>
        );
    };

    /**
     * 
     * @param children
     * @returns JSX.Element
     * @description ItemGrid is a component that displays a grid with a width of 100% and a margin of 20px
     */
    const ItemGrid = ({ children }): JSX.Element => {
        return (
            <Grid {...props} item sx={{ width: '100%', m: '20px auto 20px auto' }}>
                {children}
            </Grid>
        );
    };

    /**
     * 
     * @param children 
     * @param title
     * @param avgRate
     * @returns JSX.Element
     * @description CommentsCard is a component that displays a card with a border radius of 15px and a box shadow
     */
    const CommentsCard = (props): JSX.Element => {
        const { children, title, avgRate } = props;
        return (
            <Card
                sx={{
                    ...styles.cardView,
                    minHeight: '10px',
                }}
            >
                <CardContent>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <CardTitle sx={{ textTransform: 'capitalize' }}>{title}</CardTitle>
                        <StarRating rating={avgRate as string} />
                    </div>
                    {children}
                </CardContent>
            </Card>
        );
    };

    /**
     * 
     * @param date 
     * @param workingHours 
     * @param additionalHours
     * @returns String
     * @description This function is used to format the date and time in the required format ex: 12 Aug 2021 | 10:00am - 12:00pm
     */
    function getFormattedDate(date: string, workingHours: number, additionalHours: number) {

        // If additionalHours is not null, add it to the workingHours else return the formatted date
        const addHour = additionalHours ? additionalHours : 0
        // Add the additional hours to the working hours
        workingHours = workingHours + addHour

        // Return the formatted date
        return `${moment(date).format('DD MMM YYYY | hh:mma - ')} ${moment(date).add(workingHours, 'hours').format('hh:mma')}`
    }

    // Tags component to display review tags
    const Tags = ({ tags }) => {

        return (
            <>
                {
                    tags && tags.map((item: string, index: number) => {
                        if (item == "") return <> </>;
                        return (<div style={{ margin: '10px 5px 0px 0px' }} key={index}><Label borderRadius={"20px"} color={"primary"}>{item.replace("[", "").replace("]", "").trim()}</Label></div>)
                    })
                }
            </>
        )
    }

    // Review card component
    return (
        <ItemGrid>
            <CommentsCard title={`${data.reviewBy.fname} ${data.reviewBy.lname}`} avgRate={data.rate}>
                <div style={{ marginBottom: '15px', display: 'flex', justifyContent: 'space-between', alignItems: 'start' }}>
                    <div>
                        <DescriptionText>{data.bookingDetail.service.title}</DescriptionText>
                        {props.displayDate && <DescriptionText>{data.createdAt &&
                            getFormattedDate(data.bookingDetail.startTime, data.bookingDetail.workingHours, data.bookingDetail?.additionalTimeDetails[0]?.hours)}</DescriptionText>}
                    </div>
                    {
                        props.navigate && <Typography color={"primary"} onClick={() => props.navigate(`navigate_Id=${data.bookingId}`)} style={{ cursor: 'pointer' }}>Booking Details</Typography>
                    }
                </div>
                <Typography>
                    {data.description}
                </Typography>
                {data.tag && <div style={{ display: "flex", flexWrap: 'wrap' }}><Tags tags={data.tag?.split(",")} /></div>}
            </CommentsCard>
        </ItemGrid>
    )
}

export default ReviewCard