import { useSearchParams } from 'react-router-dom';
import ReviewCard from 'src/components/ReviewCard';
import { CleanerReviewModel } from 'src/models/Model';


// This is an interface that defines the props for the component
interface Props {
    loading: boolean;
    data: CleanerReviewModel[]
}


// This is a functional component that renders a list of reviews for a cleaner
const CleanerReviewList = (props: Props) => {

    // Get search params from URL to navigate to other pages in the app 
    const [searchParams, setSearchParams] = useSearchParams();

    // Sort review by date and time in descending order (newest first)
    // If createdAt is null, set it to 0 so that it will be sorted to the bottom
    const data = props.data?.sort((a: CleanerReviewModel, b) => {

        // If createdAt is null, set it to 0 so that it will be sorted to the bottom
        return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
    });

    // Return JSX
    return (<div style={{ width: '100vw', maxWidth: '480px' }}>
        {data?.map((item, index) => <ReviewCard displayDate navigate={setSearchParams} data={item} key={index} />)}
    </div>)
}

export default CleanerReviewList