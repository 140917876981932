import { FC, useState, createContext, useEffect } from 'react';
import { BookingFilter, BookingStatusModel, CleanersModel } from 'src/models/Model';

type GlobalContext = {
  cleanerDetails: CleanersModel;
  setCleanerDetails: any;
  refreshTables: boolean;
  refresh: any;
  refreshBookingDetails: boolean;
  refreshBookingDetail: () => void
  bookingFilter: BookingFilter
  setBookingsFilter: (status: BookingFilter) => void
  additionalCleaningAmount:number
  setAdditionalCleaningAmount:(amount: number) => void
  additionalCleaningHours:number
  setAdditionalCleaningHours:(time: number) => void
  refreshServiceDetails:boolean;
  refreshServiceDetail: () => void
};

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GlobalContext = createContext({} as GlobalContext);

export const GlobalContextProvider: FC = ({ children }) => {
  const [cleanerDetails, setCleanerDetails] = useState<CleanersModel>();
  const [refreshTables, setRefreshTable] = useState<boolean>();
  const [bookingFilter, setBookingsFilter] = useState<BookingFilter>(0)
  const [refreshBookingDetails, setRefreshBookingDetails] = useState<boolean>(false)

  const refresh = () => setRefreshTable((state) => !state);
  const refreshBookingDetail = () => 
  {
    setRefreshBookingDetails(state => !state)
    setAdditionalCleaningAmount(0)  
    setAdditionalCleaningHours(0)            
  }
  const [additionalCleaningAmount, setAdditionalCleaningAmount] = useState<number>(0)
  const [additionalCleaningHours, setAdditionalCleaningHours] = useState<number>(0)

  const [refreshServiceDetails, setRefreshServiceDetails] = useState<boolean>(false)
  const refreshServiceDetail = () => 
  {
    setRefreshServiceDetails(state => !state)
          
  }

  return (
    <GlobalContext.Provider
      value={{
        cleanerDetails,
        setCleanerDetails,
        refresh /* function call to refresh tables */,
        refreshTables /* refresh table when this state is changed  */,
        bookingFilter,
        setBookingsFilter,
        refreshBookingDetails,
        refreshBookingDetail,
        additionalCleaningAmount,
        setAdditionalCleaningAmount,
        additionalCleaningHours, 
        setAdditionalCleaningHours,
        refreshServiceDetails, 
        refreshServiceDetail
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};
