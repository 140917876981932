export const LOGIN = '/gateway/public/api/v1/admin';
export const CLEANER = '/gigoclean/admin/api/v1/cleaner';
export const GET_USER_LIST = '/gigoclean/admin/api/v1/user';
export const GET_BOOKINGS = '/gigoclean/admin/api/v1/booking';
export const GET_USER_DETAILS = '/gigoclean/admin/api/v1/user/details';
export const UPLOAD_PROFILE_PICTURE = '/gigoclean/public/api/v1/file';
export const AGREEMENT_API = '/gigoclean/public/api/v1/file';
export const BOOKING_DETAILS = '/gigoclean/admin/api/v1/booking/details';
export const CLEANER_STATUS = '/gigoclean/admin/api/v1/cleaner/status';
export const DASHBOARD = '/gigoclean/api/v1/dashboard';
export const UPDATE_CANCEL_BOOKING_STATUS =
  '/gigoclean/api/v1/booking/bookingRefund';
export const GET_SERVICE_OFFER_LIST = '/gigoclean/admin/api/v1/service';
export const GET_SERVICE_OFFER_DETAIL =
  '/gigoclean/admin/api/v1/service/detail';
export const CLEANER_RATING = '/gigoclean/api/v1/review';
