import React from 'react'
import StarIcon from 'src/assets/icons/Star.svg'
import StarGrayIcon from 'src/assets/icons/StarGray.svg'


// Props interface for the component
interface Props {
    rating: string;
}

/**
 * @description This component is used to display the star rating. 
 * It takes the rating as a prop and displays the star rating accordingly.
 * The rating is a string and can be a decimal number between 0.0 and 5.0
 * @param rating
 * @returns JSX.Element
 */
const StarRating = ({ rating }: Props) => {

    // Star component used to display the star rating
    const Star = ({ count }) => {

        // Map to map the decimal number to the width of the star
        const map = {
            0: "0px", .1: "2px", .2: "4px", .3: "6px", .4: "8px", .5: "10px", .6: "12px", .7: "14px", .8: "16px", .9: "18px", 1: "20px",
        }

        // Return the JSX to display the star rating
        return (
            // Position relative is used to position the star icon and the gray star icon
            <div style={{ position: 'relative' }}>
                <img style={{ objectPosition: 'left', objectFit: 'cover', height: '20px', width: map[count], position: 'absolute' }} src={StarIcon} alt="star icon" />
                <img style={{ objectPosition: 'left', objectFit: 'cover', height: '20px', width: '20px', }} src={StarGrayIcon} alt="star icon" />
            </div>
        )
    }

    /**
     * 
     * @param num 
     * @returns array of decimal numbers eg. 3.5 => [1, 1, 1, 0.5, 0]
     * @description This function converts the decimal number to an array of decimal numbers
     */
    function decimalToArr(num: number) {

        // If the number is less than 0 or greater than 5, return an empty array
        if (num < 0.0 || num > 5.0) {
            return [];
        }

        const arr = [0, 0, 0, 0, 0];
        const intPart = Math.floor(num);
        const decimalPart = (num - intPart).toFixed(1);

        // Loop through the array and set the values to 1.0
        for (let i = 0; i < intPart; i++) {
            arr[i] = 1.0;
        }

        // If the decimal part is greater than 0, set the value of the array at the index of the integer part to the decimal part
        if (Number(decimalPart) > 0) {
            const decimalIndex = intPart;
            arr[decimalIndex] = Number(decimalPart);
        }

        return arr;
    }

    // Return the JSX to display the star rating
    return (
        <div style={{ display: 'flex' }}>
            {
                decimalToArr(Number(rating)).map((item, index) => {
                    return <Star count={item} key={index} />
                })
            }
        </div>
    )
}

// Export the component
export default StarRating