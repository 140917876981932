export class Utils {
  private static lastNonNullInput: string | null = null;

  /**
   * @description Returns the input if it is not null, otherwise returns the last non-null input.
   * @param input String
   * @returns String
   * 
   */
  public static stringFallback(input: string | null): string {
    if (input !== null) {
      this.lastNonNullInput = input;
    }
    return this.lastNonNullInput ?? '';
  }
}
