import { useState } from 'react';
import { useEffect, useContext } from 'react';
import {
  Box,
  Typography,
  Grid,
  Card,
  CardContent,
  Chip,
  FormControl,
  Select,
  MenuItem
} from '@mui/material';
import Divider from '@mui/material/Divider';
import MuiAvatar from '@mui/material/Avatar';
import {
  AdditionalTimeConst,
  AdditionalTimeDetails,
  AdditionalTimeInterface,
  BookingCancel,
  Bookings,
  BookingStatusModel,
  BOOKING_STATUS,
  CleanerReviewModel,
  RefundStatusConst
} from 'src/models/Model';
import moment from 'moment';
import Api from 'src/api';
import { toast } from 'react-toastify';
import { GlobalContext } from 'src/contexts/GlobalContext';
import RejectAdditionalTimeRequest from 'src/assets/icons/RejectCleaningRequest.svg';
import AcceptAdditionalTimeRequest from 'src/assets/icons/AcceptCleaningRequest.svg';
import CancelAdditionalTimeRequest from 'src/assets/icons/CancelCleaningRequest.svg';
import PendingAdditionalTimeRequest from 'src/assets/icons/PendingCleaningRequest.svg';
import StarRating from 'src/components/StarRatings';
import Label from 'src/components/Label';

type Props = {
  loading: boolean | false;
  data: Bookings;
};

const BookingDetails = (props: Props) => {
  const [serviceList, setServiceList] = useState<string[][]>([]);
  const [refundStatusDisplay, setRefundStatus] = useState<number>()
  const { loading, data: bookingDetails } = props;
  const {
    refreshBookingDetail,
    setAdditionalCleaningAmount,
    additionalCleaningAmount,
    additionalCleaningHours,
    setAdditionalCleaningHours
  } = useContext(GlobalContext);

  const styles = {
    flexBetween: {
      display: 'flex',
      justifyContent: 'space-between'
    },
    grayText: {
      fontSize: '16px',
      color: '#999999'
    },
    buttonSmall: {
      padding: '2px 3px',
      color: 'primary'
    },
    grayCard: {
      backgroundColor: '#f8f8f8',
      borderRadius: '10px',
      marginTop: '10px',
      padding: '13px'
    },
    profilePicture: {
      width: '60px',
      height: '60px'
    },
    cardView: {
      borderRadius: '15px',
      boxShadow: '0px 5px 15px #e0e0e0'
    },
    cardTitle: {
      fontSize: '16px',
      fontWeight: 'bolder'
    }
  };

  const getDate = () => {
    return moment(bookingDetails?.startTime).format('MMM DD YYYY');
  };

  /**
   * @param {string} startTime - The time at which the cleaning starts. It is a string in hh:mm format. For example, if it is 9am then it will be 09:00 and if it is 5pm then it will be 17:00.
   * @param {string} hoursToAdd - The number of hours to add to the startTime parameter. This value can only be an integer between 1 and 12 inclusive. If this value exceeds 12 or less than 1, then an error message should appear on screen saying "Invalid input".
   */
  const formatDate = (startTime: string, hoursToAdd: string) => {
    const s = moment(startTime).format('h:mm a');
    const e = moment(startTime).add(hoursToAdd, 'hour').format('h:mm a');
    const CleaningHours = moment(startTime)
      .add(hoursToAdd, 'hour')
      .add(additionalCleaningHours, 'hour')
      .format('h:mm a');
    const toTime =
      additionalCleaningHours > 0
        ? `${s}` + '-' + `${CleaningHours}`
        : `${s}` + '-' + `${e}`;

    return <>{toTime}</>;
  };

  /**
   * This function formats the address of the user.
   * @returns {string} The formatted address.
   */
  const formatAddress = () => {
    let address:string = "";
    if (bookingDetails?.address?.add1 !== "") {address = bookingDetails?.address?.add1;}
    if (bookingDetails?.address?.add2 !== "") {address += ", " + bookingDetails?.address?.add2;}      
    if (bookingDetails?.address?.city !== "") {address += ", " + bookingDetails?.address?.city;}
    if (bookingDetails?.address?.state !== ""){address += ", " + bookingDetails?.address?.state;}
    if (bookingDetails?.address?.zip !== "") {address += ", " + bookingDetails?.address?.zip;}
    return `
      ${address}
    `;
  };

  useEffect(() => {
    const additionalHours = bookingDetails?.additionalTimeDetails?.sort(
      (a, b) => {
        return (
          new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
        );
      }
    );

    let additionalHour = []; /* set additional hour var as empty */

    if (additionalHours?.length > 0) /* if array is not empty */
      if (additionalHours[0]?.status === 1 /* ACCEPTED */) /* if additional hour request is accepted */
        if (bookingDetails?.additionalTimeDetails?.length > 0) additionalHour = ['Additional Hours', `${additionalHours[0].hours}`] /* then only show the Additional Hour */

    setServiceList([
      ['Size of house', `${bookingDetails?.houseSize} Sq.ft`],
      ['Rooms', `${bookingDetails?.rooms}`],
      ['Bathrooms', `${bookingDetails?.bathRooms}`],
      ['Number of Cleaners', `${bookingDetails?.cleanerCount}`],
      ['Working Hours', `${bookingDetails?.workingHours}`],
      additionalHour
    ]);
  }, [bookingDetails]);

  /**
   * This is a description of the DescriptionText component.
   *
   * @param {any} props - The props of the component.
   * @returns {JSX.Element} - The JSX to render.
   */
  const DescriptionText = (props: any) => {
    const { children } = props;

    return (
      <Typography sx={{ ...props.sx, ...styles.grayText }}>
        {children}
      </Typography>
    );
  };

  /**
   * This is a function that returns a card with user details.
   * @param {object} props - The props of the function.
   * @returns {object} - Returns a card with user details.
   */
  const UserDetailsCard = (props) => {
    return (
      <Card
        sx={{
          ...styles.cardView,
          ...props.sx
        }}
      >
        <CardContent>
          <CardTitle>{props.title}</CardTitle>
          <Box sx={{ mt: '10px', display: 'flex' }}>
            <MuiAvatar
              src={props.data?.profilePic || ``}
              alt="User Profile"
              sx={{ ...styles.profilePicture }}
            />
            <Box sx={{ ml: '10px' }}>
              <Typography fontSize={'16px'} fontWeight="700">
                {props.data?.fname || 'N/A'} {props.data?.lname || 'N/A'}
              </Typography>
              <Typography fontSize={'12px'}>{props.data?.email}</Typography>
              <Typography fontSize={'12px'}>
                {props.data?.dialCode} {props.data?.contactNumber}
              </Typography>
            </Box>
          </Box>
        </CardContent>
      </Card>
    );
  };

  /**
   * This function returns a JSX element.
   * @param {BookingStatusModel} status
   * @returns {JSX.Element}
   */
  const getStatusLabel = (status: BookingStatusModel): JSX.Element => {
    const map = {
      1: {
        text: 'Open',
        color: 'primary'
      },
      2: {
        text: 'Accepted',
        color: 'success'
      },
      3: {
        text: 'On My Way',
        color: 'warning'
      },
      4: {
        text: 'In Progress',
        color: 'warning'
      },
      5: {
        text: 'Completed',
        color: 'secondary'
      },
      6: {
        text: 'Cancelled',
        color: 'warning'
      }
    };

    try {
      const { text, color }: any = map[status];

      return (
        <Chip
          label={text}
          variant="outlined"
          sx={{ ...styles.buttonSmall }}
          color={color}
        />
      );
    } catch (e) {
      return (
        <Chip
          label={'text'}
          variant="outlined"
          sx={{ ...styles.buttonSmall }}
          color={'primary'}
        />
      );
    }
  };

  const CardTitle = ({ sx = {}, children }): JSX.Element => {
    return (
      <Typography sx={{ ...styles.cardTitle, ...sx }}>
        {children}
      </Typography>
    );
  };

  const ItemGrid = ({ children }): JSX.Element => {
    return (
      <Grid {...props} item sx={{ width: '100%', m: '20px auto 20px auto' }}>
        {children}
      </Grid>
    );
  };

  const CommentsCard = (props): JSX.Element => {
    const { children, title, avgRate } = props;
    return (
      <Card
        sx={{
          ...styles.cardView,
          minHeight: '10px',
        }}
      >
        <CardContent>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <CardTitle>{title}</CardTitle>
            {avgRate && <StarRating rating={avgRate as string} />}
          </div>
          {children}
        </CardContent>
      </Card>
    );
  };

  const MapLoop = ({ children, map }) => {
    return map?.map(children);
  };

  /**
 * This is a function that returns a card.
 * @returns {Card}
 */
  const ServiceDetailCard = (): JSX.Element => {
    const service = `${bookingDetails?.service.description} - $${bookingDetails?.serviceRate}/hr`;
    const totAmt = `$${bookingDetails?.amount}`;
    const date = formatDate(
      String(bookingDetails?.startTime),
      String(bookingDetails?.workingHours)
    );
    const label = getStatusLabel(bookingDetails?.status);

    return (
      <Card
        sx={{
          ...styles.cardView
        }}
      >
        <CardContent>
          <Box width={'100%'} sx={{ ...styles.flexBetween }}>
            <CardTitle>{service}</CardTitle>
            <CardTitle sx={{ fontSize: '20px' }}>
              {additionalCleaningAmount > 0
                ? `$${additionalCleaningAmount}`
                : totAmt}
            </CardTitle>
          </Box>
          <Typography>{getDate()}</Typography>
          <Box sx={{ ...styles.flexBetween }}>
            <Typography>{date}</Typography>
            {label}
          </Box>
          <Box sx={{ ...styles.grayCard }}>
            <Typography
              sx={{
                maxWidth: '90%',
                fontWeight: 'bold',
                textTransform: 'capitalize'
              }}
            >
              {formatAddress()}
            </Typography>
            <Divider sx={{ border: '1px solid #EBEBEB', marginTop: '4px' }} />

            <MapLoop map={serviceList || []}>
              {(value: string[][], key: number) => (
                <Box key={key} sx={{ ...styles.flexBetween, marginTop: '4px' }}>
                  <Typography>{value[0]}</Typography>
                  <Typography sx={{ fontWeight: 'bold' }}>
                    {value[1]}
                  </Typography>
                </Box>
              )}
            </MapLoop>
          </Box>
        </CardContent>
      </Card>
    );
  };

  /**
 * This is a function that returns a component.
 * @param {object} data - This is the data object.
 * @returns {React.ReactNode} - This is the component.
 */

  const CancellationDetails = ({ data }: { data: BookingCancel }) => {
    const refundStatus: any = data?.refundStatus;
    // console.log(data?.refundStatus)
    const handleChangeRefundStatus = (refundState: any) => {
      Api.changeCancelBookingRefundStatus(
        Number(data.bookingCancelId),
        refundState,
        ({ status, data }) => {
          if (status && data.status === 200) {
            refreshBookingDetail();
          } else {
            toast.error(data.message);
          }
        }
      );
    };

    const date = new Date(data.createdAt);
    const hour = date.toLocaleString('en-US', {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true
    });
    const formattedDate =
      date.getDate() > 10 ? date.getDate() : '0' + date.getDate();
    const getDate =
      [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec'
      ][date.getMonth()] +
      ' ' +
      formattedDate +
      ' ' +
      date.getFullYear() +
      ', ' +
      hour;

    // if (!data.refundStatus) {
    //   return <></>
    // }

    return (
      <Card
        sx={{
          ...styles.cardView
        }}
      >
        <CardContent>
          <CardTitle>Cancellation Details</CardTitle>
          <Box>
            <DescriptionText sx={{ mt: '10px' }}>Reason</DescriptionText>
            <Typography sx={{ fontSize: '16px' }}>{data.reason}</Typography>
          </Box>
          <Box
            sx={{
              mt: '10px',
              display: 'flex',
              justifyContent: 'space-between'
            }}
          >
            <Box>
              <DescriptionText sx={{ mt: '10px' }}>Date & Time</DescriptionText>
              <Typography sx={{ fontSize: '16px' }}>{getDate}</Typography>
            </Box>
            <Box>
              <DescriptionText sx={{ mt: '10px' }}>
                Cancelled By
              </DescriptionText>
              <Typography sx={{ fontSize: '16px' }}>
                {data?.cancelBy.fname} {data?.cancelBy.lname}
              </Typography>
            </Box>
          </Box>
          {refundStatus != null ? (
            <Box mt={3}>
              <DescriptionText sx={{ mt: '10px' }}>
                Refund Status
              </DescriptionText>
              {refundStatusDisplay == 0 ? <Typography sx={{ fontSize: '16px' }}>Not Applicable</Typography> :
                <FormControl
                  fullWidth
                  sx={{ maxWidth: '50%', mt: '7px' }}
                  size="small"
                >
                  <Select
                    id="refund-status"
                    value={refundStatus}
                    onChange={(event: any) => {
                      handleChangeRefundStatus(event.target.value);
                    }}
                  >
                    <MenuItem value={1}>{RefundStatusConst[1]}</MenuItem>
                    <MenuItem value={2}>{RefundStatusConst[2]}</MenuItem>
                  </Select>
                </FormControl>
              }
            </Box>
          ) : (
            ' '
          )}
        </CardContent>
      </Card>
    );
  };


  const RefundSummary = ({ data }: { data: BookingCancel }) => {

    setRefundStatus(data?.refundAmount)

    return (
      <Card
        sx={{
          ...styles.cardView
        }}
      >
        <CardContent>
          <CardTitle>Refund Summary</CardTitle>

          <Box sx={{ mt: '10px' }}>
            {[
              {
                name: 'Paid Amount',
                value: `$${data.charge + data.refundAmount}`
              },
              { name: 'Cancellation Charges', value: `$${data.charge}` },
              { name: 'Total Refund Amount', value: `$${data.refundAmount}` }
            ].map((value, key) => (
              <Box
                key={key}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between'
                }}
              >
                <DescriptionText key={key}>{value.name}</DescriptionText>
                <Typography>{value.value}</Typography>
              </Box>
            ))}
          </Box>
        </CardContent>
      </Card>
    );
  };

  const RefundSummaryDisplay = (refundStatus: any) => {
    return refundStatus != null ? true : false;
  };

  const getRecentCancelledBooking = (bookingCancelArray: BookingCancel[]) => {
    if (!bookingCancelArray.length) return <></>;

    let sortedBookings = bookingCancelArray.sort((a, b) => {
      return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
    });

    return (
      <Box>
        <ItemGrid>
          <CancellationDetails data={sortedBookings[0]} />
        </ItemGrid>
        {RefundSummaryDisplay(sortedBookings[0].refundStatus) ? (
          <ItemGrid>
            <RefundSummary data={sortedBookings[0]} />
          </ItemGrid>
        ) : (
          ''
        )}
      </Box>
    );
  };

  const AdditionalCleaningRequest = (booking: Bookings) => {

    const data = booking?.additionalTimeDetails;

    if (!data?.length) return <></>;

    let CleaningRequest = data.sort((a, b) => {
      return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
    });

    const getIcon = (status: AdditionalTimeInterface) => {
      switch (status) {
        case 0: return <img src={PendingAdditionalTimeRequest} />;
        case 1: return <img src={AcceptAdditionalTimeRequest} />;
        case 2: return <img src={RejectAdditionalTimeRequest} />;
        case null: return <img src={CancelAdditionalTimeRequest} />;
      }
    }

    const getRequestStatus = (requestStatus: AdditionalTimeInterface, bookingStatus: BookingStatusModel) => {

      const statusComponent = (status: AdditionalTimeInterface) => <Box
        sx={{
          ...styles.grayCard,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          mt: '15px'
        }}
      >
        {getIcon(status)} {/* returns img element */}
        <Typography
          sx={{
            maxWidth: '70%',
            textTransform: 'capitalize',
            fontSize: '16px',
            fontWeight: '700',
            color:
              status === 0 ? '#FCC419' : status === 1 ? '#09B9B6' : '#FE2245', /* logic is complicated, need to change method or define constant */
            ml: 1
          }}
        >
          {status === 0 ? 'Request Pending' : AdditionalTimeConst[status]}
        </Typography>
      </Box>

      // flow is not clear, may need to change the function 
      switch (requestStatus) {
        case 1:
          return statusComponent(1)
        case 2:
          return statusComponent(2)
        case null: {
          if (bookingStatus === 5) {
            return statusComponent(requestStatus)
          } else if (bookingStatus === 4) {
            return statusComponent(0)
          }
          return <></>
        }
      }
    }
    return (
      <Card
        sx={{
          ...styles.cardView
        }}
      >
        <CardContent>
          <CardTitle>Additional Cleaning Request</CardTitle>

          <Box sx={{ mt: '10px' }}>
            {[
              { name: 'Additional Hrs', value: `${CleaningRequest[0].hours}` },
              {
                name: 'Additional Charges',
                value: `$${CleaningRequest[0].charge}`
              }
            ].map((value, key) => (
              <Box
                key={key}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between'
                }}
              >
                <DescriptionText key={key}>{value.name}</DescriptionText>
                <Typography sx={{ fontWeight: 'bold' }}>
                  {value.value}
                </Typography>
              </Box>
            ))}
            <Box>
              <Typography
                sx={{
                  maxWidth: '70%',
                  fontWeight: 'bold',
                  textTransform: 'capitalize',
                  mt: '15px'
                }}
              >
                Reason
              </Typography>
              <Typography>
                {CleaningRequest[0]?.reason || 'No Reason.'}
              </Typography>
            </Box>
            {
              getRequestStatus(CleaningRequest[0]?.status, booking?.status)
            }
          </Box>
        </CardContent>
      </Card>
    );
  };

  const CleaningRequestAmount = (
    data: AdditionalTimeDetails[]
  ): React.ReactNode => {
    if (!data?.length) return <></>;

    const CleaningRequest = data.sort((a, b) => {
      return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
    });

    const object = CleaningRequest[0];
    const totalAmount: number = bookingDetails?.amount + object?.charge;

    setAdditionalCleaningAmount(object?.status === 1 ? totalAmount : 0);
    setAdditionalCleaningHours(object?.status === 1 ? object?.hours : 0);

    return object?.status === 1 ? (
      <Card sx={{ ...styles.cardView }}>
        <CardContent>
          <Box sx={{ mt: '10px' }}>
            {[
              { name: 'Amount Paid', value: `$${bookingDetails?.amount}` },
              { name: 'Additional Charges', value: `$${object?.charge}` }
            ].map((value, key) => (
              <Box
                key={key}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between'
                }}
              >
                <DescriptionText key={key}>{value.name}</DescriptionText>
                <Typography>{value.value}</Typography>
              </Box>
            ))}
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                mt: '10px'
              }}
            >
              <DescriptionText
                sx={{ color: '#002D2C', fontSize: '14px', fontWeight: '700' }}
              >
                Total Amount Paid
              </DescriptionText>
              <Typography
                sx={{ color: '#0FBAB6', fontSize: '14px', fontWeight: '900' }}
              >
                {`$${bookingDetails?.amount + object?.charge}`}
              </Typography>
            </Box>
          </Box>
        </CardContent>
      </Card>
    ) : (
      <></>
    );
  };

  const SectionTitle = ({ children }): JSX.Element => (
    <Typography fontSize={"medium"} fontWeight={600} style={{ paddingTop: '20px' }}>{children}</Typography>
  )

  const Tags = ({ tags }) => {

    return (
      <>
        {
          tags && tags.map((item: string, index: number) => {
            if (item == '') return <></>;
            return <div style={{ margin: '10px 5px 0px 0px' }} key={index}><Label borderRadius={"20px"} color={"primary"}>{item.replace("[", "").replace("]", "").trim()}</Label></div>
          }
          )
        }
      </>
    )
  }

  return (
    <div
      style={{
        visibility: loading ? 'hidden' : 'visible',
        width: '100vw',
        maxWidth: '480px'
      }}
    >
      <ItemGrid>
        <ServiceDetailCard />
      </ItemGrid>
      <ItemGrid>
        <CommentsCard title="Specific Instructions">
          <Typography>
            {bookingDetails?.instructions || 'No Instruction.'}
          </Typography>
        </CommentsCard>
      </ItemGrid>
      <ItemGrid>
        {AdditionalCleaningRequest(bookingDetails)}
      </ItemGrid>
      <ItemGrid>
        {CleaningRequestAmount(bookingDetails?.additionalTimeDetails)}
      </ItemGrid>
      <ItemGrid>
        {getRecentCancelledBooking(bookingDetails?.bookingCancel || [])}
      </ItemGrid>
      <ItemGrid>
        <UserDetailsCard title="Customer" data={bookingDetails?.bookedBy} />
      </ItemGrid>
      {bookingDetails?.cleaner && (
        <ItemGrid>
          <UserDetailsCard title="Cleaner" data={bookingDetails?.cleaner} />
        </ItemGrid>
      )}
      {
        bookingDetails?.bookingReview && (<ItemGrid>
          <CommentsCard title={`Customer Review`} avgRate={bookingDetails?.bookingReview.rate}>
            <Typography style={{ marginTop: '10px' }}>
              {bookingDetails?.bookingReview.description}
            </Typography>
            {
              bookingDetails?.bookingReview?.tag && <div style={{ display: "flex", flexWrap: 'wrap' }}><Tags tags={bookingDetails?.bookingReview.tag?.split(",")} /></div>
            }
          </CommentsCard>
        </ItemGrid>)
      }
    </div>
  );
};

export default BookingDetails;
