import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';
import { RouteObject } from 'react-router';

import SidebarLayout from 'src/layouts/SidebarLayout';
import BaseLayout from 'src/layouts/BaseLayout';

import SuspenseLoader from 'src/components/SuspenseLoader';

const Loader = (Component: any) => (props: any) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

const Login = Loader(lazy(() => import('src/content/applications/Login')));
const CleanersTable = Loader(
  lazy(() => import('src/content/applications/Cleaners'))
);

const CleanerAccrual = Loader(
  lazy(() => import('src/content/applications/CleanerAccrual'))
);

const CustomersTable = Loader(
  lazy(() => import('src/content/applications/Customers'))
);
const BookingsTable = Loader(
  lazy(() => import('src/content/applications/Bookings'))
);
const ServicesTable = Loader(
  lazy(() => import('src/content/applications/Services'))
);
const Status404 = Loader(
  lazy(() => import('src/content/pages/Status/Status404'))
);

const routes: RouteObject[] = [
  {
    path: '',
    element: <BaseLayout />,
    children: [
      {
        path: '/',
        element: <Navigate to="/login" replace />
      },
      {
        path: '*',
        element: <Status404 />
      }
    ]
  },
  {
    path: 'login',
    element: <Login />
  },
  {
    path: 'dashboard',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <Navigate to="cleaners" replace />
      },
      {
        path: 'cleaners',
        element: <CleanersTable />
      },
      {
        path: 'accrual',
        element: <CleanerAccrual />
      },
      {
        path: 'customers',
        element: <CustomersTable />
      },
      {
        path: 'bookings',
        element: <BookingsTable />
      },
      {
        path: 'services',
        element: <ServicesTable />
      },

    ]
  }
];

export default routes;

// Pages

// const Overview = Loader(lazy(() => import('src/content/overview')));

// Dashboards

// const Crypto = Loader(lazy(() => import('src/content/dashboards/Crypto')));

// Applications

// const Messenger = Loader(
//   lazy(() => import('src/content/applications/Messenger'))
// );

// const UserProfile = Loader(
//   lazy(() => import('src/content/applications/Users/profile'))
// );
// const UserSettings = Loader(
//   lazy(() => import('src/content/applications/Users/settings'))
// );

// Components

// const Buttons = Loader(
//   lazy(() => import('src/content/pages/Components/Buttons'))
// );
// const Modals = Loader(
//   lazy(() => import('src/content/pages/Components/Modals'))
// );
// const Accordions = Loader(
//   lazy(() => import('src/content/pages/Components/Accordions'))
// );
// const Tabs = Loader(lazy(() => import('src/content/pages/Components/Tabs')));
// const Badges = Loader(
//   lazy(() => import('src/content/pages/Components/Badges'))
// );
// const Tooltips = Loader(
//   lazy(() => import('src/content/pages/Components/Tooltips'))
// );
// const Avatars = Loader(
//   lazy(() => import('src/content/pages/Components/Avatars'))
// );
// const Cards = Loader(lazy(() => import('src/content/pages/Components/Cards')));
// const Forms = Loader(lazy(() => import('src/content/pages/Components/Forms')));

// Status

// const Status500 = Loader(
//   lazy(() => import('src/content/pages/Status/Status500'))
// );
// const StatusComingSoon = Loader(
//   lazy(() => import('src/content/pages/Status/ComingSoon'))
// );
// const StatusMaintenance = Loader(
//   lazy(() => import('src/content/pages/Status/Maintenance'))
// );

// const routes: RouteObject[] = [
//   {
//     path: '',
//     element: <BaseLayout />,
//     children: [
//       {
//         path: '/',
//         element: <Overview />
//       },
//       {
//         path: 'overview',
//         element: <Navigate to="/" replace />
//       },
//       {
//         path: 'status',
//         children: [
//           {
//             path: '',
//             element: <Navigate to="404" replace />
//           },
//           {
//             path: '404',
//             element: <Status404 />
//           },
//           {
//             path: '500',
//             element: <Status500 />
//           },
//           {
//             path: 'maintenance',
//             element: <StatusMaintenance />
//           },
//           {
//             path: 'coming-soon',
//             element: <StatusComingSoon />
//           }
//         ]
//       },
//       {
//         path: '*',
//         element: <Status404 />
//       }
//     ]
//   },
//   {
//     path: 'dashboards',
//     element: <SidebarLayout />,
//     children: [
//       {
//         path: '',
//         element: <Navigate to="crypto" replace />
//       },
//       {
//         path: 'crypto',
//         element: <Crypto />
//       },
//       {
//         path: 'messenger',
//         element: <Messenger />
//       }
//     ]
//   },
//   {
//     path: 'management',
//     element: <SidebarLayout />,
//     children: [
//       {
//         path: '',
//         element: <Navigate to="transactions" replace />
//       },
//       {
//         path: 'transactions',
//         element: <Transactions />
//       },
//       {
//         path: 'profile',
//         children: [
//           {
//             path: '',
//             element: <Navigate to="details" replace />
//           },
//           {
//             path: 'details',
//             element: <UserProfile />
//           },
//           {
//             path: 'settings',
//             element: <UserSettings />
//           }
//         ]
//       }
//     ]
//   },
//   {
//     path: '/components',
//     element: <SidebarLayout />,
//     children: [
//       {
//         path: '',
//         element: <Navigate to="buttons" replace />
//       },
//       {
//         path: 'buttons',
//         element: <Buttons />
//       },
//       {
//         path: 'modals',
//         element: <Modals />
//       },
//       {
//         path: 'accordions',
//         element: <Accordions />
//       },
//       {
//         path: 'tabs',
//         element: <Tabs />
//       },
//       {
//         path: 'badges',
//         element: <Badges />
//       },
//       {
//         path: 'tooltips',
//         element: <Tooltips />
//       },
//       {
//         path: 'avatars',
//         element: <Avatars />
//       },
//       {
//         path: 'cards',
//         element: <Cards />
//       },
//       {
//         path: 'forms',
//         element: <Forms />
//       }
//     ]
//   }
// ];
