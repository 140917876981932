import { Box, Typography } from '@mui/material';
import React from 'react';
import { SpinnerRoundFilled } from 'spinners-react';
import { PureLightTheme } from 'src/theme/schemes/PureLightTheme';

const Loader = () => {
  return (
    <Box
      width="100%"
      height="100%"
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <Box
        sx={{
          marginTop: 'auto',
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)'
        }}
      >
        <SpinnerRoundFilled
          color={PureLightTheme.colors.primary.main}
          enabled={true}
          speed={150}
          style={{
            marginLeft: '10px',
            marginRight: '10px'
          }}
        />
        <Typography textAlign={'center'}>Loading...</Typography>
      </Box>
    </Box>
  );
};

export default Loader;
